import React from 'react';
import SiteHeader from '../components/header.js'
import theme from '../components/theme.js'
import { ThemeProvider } from '@mui/material/styles';
import { Typography, Box } from '@mui/material';
import {Helmet} from 'react-helmet'
const HomePage = ()=>{
	return (
		<ThemeProvider theme={theme}>
			<Box>
				<Helmet defer={false}>
					<title>Sunny Audio</title>
				</Helmet>
				<SiteHeader currentPage=""/>
				<Typography align="center" marginTop="60px">Sorry, the requested page was not found.</Typography>
			</Box>
		</ThemeProvider>
	)
}

export default HomePage